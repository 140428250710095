<template>
  <div class="tariffs-page">
    <div class="tariffs-page__title">
      <PageTitle text="Тарифы" />
    </div>
    <el-table
      v-if="currentTariff"
      :data="tariffsList"
      :row-class-name="tableRowClassName"
      style="width: 100%"
      class="tariffs-page__table"
    >
      <el-table-column prop="name" />
      <el-table-column prop="project_count" label="Проекты" />
      <el-table-column prop="user_count" label="Пользователи" />
      <el-table-column
        prop="can_download_project_stats"
        label="Статистика по проектам"
      >
        <template slot-scope="scope">
          {{ scope.row?.can_download_project_stats ? "Да" : "Нет" }}
        </template>
      </el-table-column>
      <el-table-column
        prop="can_download_user_stats"
        label="Статистика по пользователям"
      >
        <template slot-scope="scope">
          {{ scope.row?.can_download_user_stats ? "Да" : "Нет" }}
        </template>
      </el-table-column>

      <el-table-column label="Дата окончания">
        <template slot-scope="scope">
          <span v-if="scope.row?.id === 0">
            Бессрочно
          </span>
          <span
            v-else-if="currentTariff && scope.row?.name === currentTariff.name"
          >
            {{
              currentTariff.date_to
                .split("T")[0]
                .split("-")
                .reverse()
                .join(".")
            }}
          </span>
        </template>
      </el-table-column>
      <el-table-column width="300" label="Стоимость">
        <template slot-scope="scope">
          <div v-for="period in scope.row?.tariff_periods" :key="period.price">
            <span v-if="scope.row?.id === 0">
              {{ period.price }}
            </span>
            <span v-else class="tariffs-page__cost">
              {{ `${period.months}м - ${period.price.split(".")[0]} ₽` }}
              <el-button
                :disabled="
                  currentTariff.project_count > scope.row?.project_count
                "
                @click="buyTariff(period.id)"
              >
                {{
                  scope.row?.name !== currentTariff.name
                    ? "Приобрести"
                    : "Продлить"
                }}
              </el-button>
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import PageTitle from "../components/elements/PageTitle";

export default {
  name: "Tariffs",
  components: {
    PageTitle
  },
  computed: {
    tariffsList() {
      const trial = [
        {
          id: 0,
          name: "Пробный",
          project_count: 1,
          user_count: 1,
          can_download_project_stats: 0,
          can_download_user_stats: 0,
          tariff_periods: [{ price: "Бесплатно" }]
        }
      ];

      return trial.concat(this.$store.state.Tariffs.tariffsList);
    },
    currentTariff() {
      return this.$store.getters["Tariffs/currentTariff"];
    }
  },
  methods: {
    tableRowClassName({ row }) {
      if (this.currentTariff && row.name === this.currentTariff.name) {
        return "active-row";
      }
      return "";
    },
    getTariffs() {
      this.$store.dispatch("Tariffs/getTariffs");
    },
    buyTariff(id) {
      const data = {
        tariff_period_id: id
      };
      this.$store.dispatch("Tariffs/buyTariff", data).then(resp => {
        const paymentUrl = resp.data.data.payment_url;
        window.open(paymentUrl, "_blank");
      });
    }
  },
  created() {
    this.getTariffs();
  }
};
</script>

<style scoped lang="scss">
@import "src/assets/styles/helpers/variables";
@import "src/assets/styles/helpers/mixin";

.tariffs-page {
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 2.4rem;
  }

  &__table {
    ::v-deep th.is-leaf .cell {
      padding: 0;
    }
  }

  &__cost {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:first-of-type {
      margin-bottom: 10px;
    }
  }
}
</style>
